<template>
  <NuxtLink
    :to="to"
    :prefetch="prefetch"
    class="group inline-grid grid-cols-[repeat(2,fit-content(65vw))] justify-start items-start md:items-center gap-4"
  >
    <span class="block flex-initial max-w-[80ch]"><slot /></span>
    <span
      class="action w-fit max-w-fit flex-grow flex-shrink-0 inline-flex flex-nowrap flex-row items-center gap-2 px-2 py-2 overflow-clip border-2 border-brand-midnight rounded-full"
    >
      <span
        class="label flex-grow basis-[fit-content] h-4 w-fit text-base leading-none"
      >
        {{ label || t('More') }}
      </span>
      <span class="icon m-0 p-0 flex-shrink-0"></span>
    </span>
  </NuxtLink>
</template>

<script lang="ts" setup>
const props = defineProps<{
  label?: string
  to: string
  prefetch?: boolean
}>()

const { t } = useI18n()
</script>

<style lang="postcss">
.rmc + [data-icon] {
  display: none;
}
</style>

<style lang="postcss" scoped>
.icon {
  width: 1em;
  height: 1em;
  line-height: 1;
  background-image: url('data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22chevron-right%22%20class%3D%22svg-inline--fa%20fa-chevron-right%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M285.476%20272.971L91.132%20467.314c-9.373%209.373-24.569%209.373-33.941%200l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505%20256%2034.484%20101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373%2024.569-9.373%2033.941%200L285.475%20239.03c9.373%209.372%209.373%2024.568.001%2033.941z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (prefers-reduced-motion: no-preference) {
  .action {
    @apply duration-200 transition-all group-hover:px-4;
  }

  .label {
    @apply absolute duration-200 delay-0 opacity-0 group-hover:opacity-100 group-hover:delay-100 transition-all;

    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transform-origin: left;

    .group:hover & {
      position: relative;
      width: fit-content;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "More": "More"
  },
  "es": {
    "More": "Todos"
  }
}
</i18n>
