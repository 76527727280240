<template>
  <header class="header flex flex-col place-content-start gap-4 space-y-4">
    <div class="title">
      <template v-if="path">
        <LinkReadMore :label="t('More')" :to="path">
          <h2 class="text-4xl font-serif font-bold">
            <slot name="title">
              {{ title }}
            </slot>
          </h2>
        </LinkReadMore>
      </template>
      <template v-else>
        <h2 class="text-4xl font-serif font-bold">
          <slot name="title">
            {{ title }}
          </slot>
        </h2>
      </template>
    </div>
    <slot name="body">
      <Wysiwyg
        v-if="body"
        class="section-summary font-sans text-xl"
        :format="body.format"
        :markup="body.markup"
      />
    </slot>
  </header>
</template>

<script setup lang="ts">
import type { ContentBody } from '~/types'
const { t } = useI18n()
defineProps<{
  title: string
  path?: string
  body?: ContentBody
}>()
</script>

<i18n lang="json">
{
  "en": {
    "More": "More"
  },
  "es": {
    "More": "Más como este"
  }
}
</i18n>
